//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { queryHotelCancelOrderList } from "@/lib/data-service/hotel";
import scrollTop from "@/lib/common-service/scrollTop";
import { STATUS } from "@/lib/deprecated/utils/status";
const SUCCESS_CODE = STATUS.success;
export default {
  data() {
    return {
      formData: {
        pageSize: 10,
        currentPage: 1,
        cancelStatus: "-1"
      },
      totalPage: 0,
      value1: "",
      tabelNav: [
        { text: "全部订单", statu: true, val: "-1" },
        // {text:'待支付',statu:false,val:0},
        // {text:'支付中',statu:false,val:1},
        // {text:'待确认',statu:false,val:2},
        // {text:'已确认',statu:false,val:3},
        // {text:'已拒单',statu:false,val:4},
        // {text:'已取消',statu:false,val:5},
        { text: "申请取消", statu: false, val: 0 },
        { text: "取消成功", statu: false, val: 1 },
        { text: "待平台确认", statu: false, val: 2 },
        { text: "取消失败", statu: false, val: 3 }
      ],
      tableData: [],
      cancelText: "",
      img: `../https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/src/assets/hotel.jpg`
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //表格类型搜索
    getNavList(val, index) {
      let data = this.formData;
      this.tabelNav.map(item => {
        item.statu = false;
      });
      this.$nextTick(() => {
        this.tabelNav[index].statu = true;
      });
      data.cancelStatus = val.val;
      this.formData.currentPage = 1;
      this.getList();
    },
    reset() {
      this.formData = {
        pageSize: 10,
        currentPage: 1
      };
    },
    //表格选择
    handleSelectionChange(val) {
      console.log(val);
    },
    //分页
    handleCurrentChange(val) {
      this.formData.currentPage = val;
      this.getList();
    },
    //获取订单列表
    getList() {
      let data = Object.assign({}, this.formData);
      data.createTime = data.createTime ? data.createTime : "";
      data.cancelTime = data.cancelTime ? data.cancelTime : "";
      queryHotelCancelOrderList(data).then(res => {
        // console.log(res)
        if (res.code === SUCCESS_CODE) {
          scrollTop(500);
          this.tableData = res.pageResult.pageData;
          this.totalPage = res.pageResult.totalCount;
        }
      });
    },
    //查看订单
    goInfo(val) {
      this.$router.push({
        path: "hotel-manage-order-info",
        query: { orderNo: val.orderNo, type: "cancel" }
      });
    },
    //取消订单
    cancelOrder(val) {
      // console.log(val)
      let data = {
        orderNo: val.orderNo
      };
      this.$prompt("请输入取消原因：", "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({ value }) => {
          if (!value)
            return this.$message({
              type: "warning",
              message: "请填写取消原因"
            });
          data.cancelRemark = value;
          cancelOrderSubmit(data).then(res => {
            if (res.code === SUCCESS_CODE) {
              this.$message({ type: "success", message: "取消订单成功" });
              this.getList();
            }
          });
        })
        .catch(() => {});
    }
  }
};
